.swiper-button-prev,
.swiper-button-next {
  color: yellow !important;
}

.scrollbar-thin {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: gray transparent; /* For Firefox */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #4b5563; /* Gray color for the thumb */
  border-radius: 20px; /* Rounded edges */
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: #6b7280; /* Slightly lighter gray on hover */
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent; /* Transparent background for the track */
}

.scrollbar-thin::-webkit-scrollbar-button {
  display: none !important; /* Ensure buttons are hidden */
  width: 0; /* Set width to 0 to ensure no extra space for arrows */
  height: 0; /* Set height to 0 to ensure no extra space for arrows */
}

/* Specific Fix for Windows/Edge Scrollbars */
.scrollbar-thin::-webkit-scrollbar-button:vertical {
  display: none !important;
}

