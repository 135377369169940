.inst-btn{
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
    padding: 10px;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid white;
    border-radius: 10px;
    background: transparent;
    align-items: center;
    justify-content: space-around;
}