@font-face {
  font-family: "Rubik";
  src: url("assests/fonts/Rubik-VariableFont_wght.ttf");
}

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
}

@font-face {
  font-family: "Inter";
  src: url("assests/fonts/Inter-VariableFont_opsz_wght.ttf");
}
body {
  overflow-x: hidden;
  font-family: "Inter" !important;
  scrollbar-width: thin;
  scrollbar-color: lightgrey transparent;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@layer utilities {
  .border-gradient {
    position: relative;
    border-radius: 20px;
    padding: 1px;
  }

  .border-gradient::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    opacity: 80%;
    padding: 1px;
    background: linear-gradient(to right, var(--tw-gradient-stops));
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: subtract;
    pointer-events: none;
  }
}
